@tailwind base;
@tailwind components;
@tailwind utilities;

.custom_success_bg {
    @apply bg-green-600;
}

.custom_error_bg {
    @apply bg-red-600;
}


.payment_control {
    @apply w-full px-3 py-3 mb-1 border-2 border-gray-200 rounded-md transition-colors focus:outline-none focus:border-primary;
}

:root,
[data-theme] {
    color: #ffffff;
    --b1: 0 0% 7% !important;
}

.bg-mdyellow {
    background-color: #d39e28;
    color: #000000;
}
.btn.bg-mdyellow:hover {
    background-color: #4d4121;
    color: #eeeeee;
}
.bg-ltyellow {
    background-color: #f6de8b;
    color: #000000;
}
.bg-dkyellow {
    background-color: #4d4121;
    color: #000000;
}

@media (max-width: 1023px) {
    .mx-24 {
        margin: 4rem 0 !important;
    }
    .p-20 {
        padding: 2rem !important;
    }
}
